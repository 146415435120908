import React, {ReactElement} from "react";
import styles from "./category.module.scss";
import classnames from "classnames";


export interface CategoryProps {
	category: string;
	isSelected: boolean;
	clicked?: any;
}
export default function Category(props: CategoryProps) : ReactElement {
	return <div className={classnames(
		styles.category,
		{[styles.isSelected]: props.isSelected},
	)}
	onClick={() => props.clicked(props.category)}
	>
		{props.category}
	</div>;
}
