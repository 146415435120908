import React, {ReactElement} from "react";
import "./App.scss";
import Dashboard from "./routes/dashboard";
import {Routes, Route} from "react-router-dom";
import Interests from "./routes/interests";
import Brands from "./routes/brands";
import Profile from "./routes/profile";
import TermOfService from "./routes/profile/terms-of-service";
import PersonalProfile from "./routes/profile/personal";
import LayoutWithNavbar from "./routes/LayoutWithNavbar";
import Settings from "./routes/profile/settings";
import Home from "./routes/home";
import {
	OnboardingBrands,
	OnboardingContainer,
	OnboardingInterests,
	OnboardingProfile,
	OnboardingSearch,
} from "./routes/onboarding";

function App() : ReactElement {
	return (
		<Routes>
			<Route path="/" element={<LayoutWithNavbar />}>
				<Route path="/" element={<Dashboard />} />
				<Route path="/interests" element={<Interests />} />
				<Route path="/brands" element={<Brands />} />
			</Route>
			<Route path="/profile" element={<Profile />}>
				<Route path="/profile/personal" element={
					<PersonalProfile header="Personal Profile" />
				} />
				<Route path="/profile/tos" element={<TermOfService />} />
				<Route path="/profile/settings" element={<Settings />} />
			</Route>

			<Route path="/welcome" element={<Home />} />

			<Route path="/onboarding" element={<OnboardingContainer />}>
				<Route path="/onboarding/interests" element={<OnboardingInterests />} />
				<Route path="/onboarding/brands" element={<OnboardingBrands />} />
				<Route path="/onboarding/profile" element={<OnboardingProfile />} />
				<Route path="/onboarding/search" element={<OnboardingSearch />} />
			</Route>
		</Routes>
	);
}

export default App;
