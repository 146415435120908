import React, {ReactElement} from "react";
import styles from "./tips.module.scss";
import classnames, {Argument} from "classnames";

export interface TipInterface {
	title: string;
	description: string;
	icon: React.ReactNode;
}

export interface TipsProps {
	className?: Argument;
	header: string;
	tips: TipInterface[];
}

export const Tips = ({tips, header, className}: TipsProps): ReactElement => (
	<div className={classnames(styles.tips, className)}>
		<h3 className={styles.header}>{header}</h3>

		{tips.map((tip, i) =>
			<div className={styles.tip} key={i}>
				<div className={styles.circle}>
					{tip.icon}
				</div>
				<div>
					<h5>{tip.title}</h5>
					<p>{tip.description}</p>
				</div>
			</div>)}
	</div>
);

