import React, {ReactElement} from "react";
import styles from "./flexible-navbar.module.scss";
import classnames, {Argument} from "classnames";
import {ReactComponent as LogoIcon} from "../../icons/logo.svg";

export interface FlexibleNavbarProps {
	className?: Argument
	children?: React.ReactNode;
}

export const FlexibleNavbar = ({children, className}: FlexibleNavbarProps): ReactElement => (
	<div className={classnames(styles.navbar, className)}>
		<LogoIcon />
		{children}
	</div>
);
