import classnames, {Argument} from "classnames";
import React, {ReactElement} from "react";
import styles from "./layout.module.scss";

export interface LayoutProps {
	children: React.ReactNode;
	className?: Argument;
}
export default function Layout(props: LayoutProps) : ReactElement {
	return <div className={classnames(styles.container, props.className)}>
		{props.children}
	</div>;
}
