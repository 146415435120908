import React, {ReactElement} from "react";
import classnames, {Argument} from "classnames";
import styles from "./header.module.scss";

export interface HeaderProps {
	className?: Argument;
	children?: React.ReactNode;
}

export const Header = ({children, className}: HeaderProps): ReactElement => (
	<h2 className={classnames(styles.header, className)}>{children}</h2>
);
