import extend from "extend";

export interface Config {
	apiHost: string;
}

export type Environment = "dev" | "test" | "prod";

const base: Partial<Config> = {
	apiHost: "https://localhost:3000",
};

const envs: {[key in Environment]: Partial<Config>} = {
	dev: {},
	test: {
		apiHost: "https://api2.vurvey.dev",
	},
	prod: {
		apiHost: "https://api.vurvey.app",
	},
};

const env = envs[process.env.REACT_APP_ENV || "dev"];
const config: Config = extend({}, base, env);

export default config;
export {config};
