import React, {ReactElement} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "../../components/button";
import {FlexibleNavbar} from "../../components/flexible-navbar";
import Layout from "../../components/layout";
import {Tips, TipInterface} from "../../components/tips";
import {ReactComponent as FlagIcon} from "../../icons/flag.svg";
import {ReactComponent as MoneySackIcon} from "../../icons/money-sack.svg";
import {ReactComponent as PersonIcon} from "../../icons/person.svg";
import styles from "./home.module.scss";

export default function Home(): ReactElement {
	const tips: TipInterface[] = [
		{
			title: "Create a free account",
			description: `Sign up for a free account and gain access
				to dozens of the world's best brands looking for customer feedback.`,
			icon: <PersonIcon />,
		},
		{
			title: "Follow the brands you love",
			description: `We'll match you with brands based on your interests so
				you'll be able to participate in research you love!`,
			icon: <FlagIcon />,
		},
		{
			title: "Participate in research and get paid!",
			description: `Test new products and share your opinions from anywhere
				in the world through the Vurvey app. Complete research studies and earn cash.`,
			icon: <MoneySackIcon />,
		},
	];

	const navigate = useNavigate();

	const navigateToOnboarding = (): void => {
		return navigate("/onboarding/interests");
	};

	return (
		<>
			<FlexibleNavbar>
				<Button text="Log In" clicked={navigateToOnboarding} />
			</FlexibleNavbar>
			<Layout className={styles.container}>
				<img
					src="images/background.png"
					alt="background-image"
					className={styles.backgroundImage}
				/>
				<div className={styles.contentContainer}>
					<div>
						<h2 className={styles.header}>
							Make it together.
							Join us.
						</h2>
						<p className={styles.description}>
							<strong>
								Share your opinions, test new products, and earn money!
							</strong>
								Vurvey is a thriving community of insightful consumers,
								industry experts, and global brands who want to improve
								the world through customer feedback.
						</p>

						<Button
							className={styles.createAccountBtn}
							text="Create a free account"
							clicked={navigateToOnboarding}
							isFullWidth={true}
						/>
					</div>
					<Tips header="How it works" tips={tips} />
				</div>
			</Layout>
		</>
	);
}
