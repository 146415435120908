import React, {ReactElement} from "react";
import styles from "./input.module.scss";
import classnames, {Argument} from "classnames";

export interface InputProps {
	label?: string;
	id: string;
	className?: Argument;
	onChange?: (newValue: any) => void;
	children?: React.ReactNode;
}

export const Input = ({id, className, label, children}: InputProps): ReactElement => (
	<div className={classnames(styles.wrapper, className)}>
		<label htmlFor={id} className={styles.label}>{label}</label>
		{children}
	</div>
);

