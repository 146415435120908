import React, {ReactElement} from "react";
import styles from "../input.module.scss";
import {Input, InputProps} from "../input";

export interface DateProps extends InputProps {
	value: string;
	onChange: (newValue: string) => void;
}

export const Date = ({value, id, onChange, ...props}: DateProps): ReactElement => (
	<Input {...props} id={id}>
		<input
			id={id}
			className={styles.input}
			type="date"
			value={value}
			onChange={e => onChange(e.target.value)}
		/>
	</Input>
);
