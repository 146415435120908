import React, {ReactElement, useState} from "react";

import {TextInput, DateInput, Select, Checkbox} from "../../../components/input";
import {ReactComponent as CameraIcon} from "../../../icons/camera.svg";
import {Button} from "../../../components/button";
import styles from "./personal.module.scss";
import {Header} from "../../../components/header";
import classnames, {Argument} from "classnames";

export interface PersonalProfileProps {
	header: string;
	description?: string;
	className?: Argument;
}

export default function PersonalProfile(props: PersonalProfileProps) : ReactElement {
	const countryList = [
		{text: "USA", value: "USA"},
		{text: "Poland", value: "Poland"},
		{text: "Albania", value: "Albania"},
	];
	const genderList = [
		{text: "Male", value: "Male"},
		{text: "Female", value: "Female"},
		{text: "Nonbinary", value: "Nonbinary"},
	];

	const ethnicityList = [
		{
			text: "East Asian (China, Hong Kong, Japan, South Korea, Taiwan)",
			value: "East Asian",
		},
		{
			text: "South Asian (India, Nepal, Pakistan, Afghanistan, Bangladesh, Sri Lanka)",
			value: "South Asian",
		},
		{
			text: "Black",
			value: "Black",
		},
		{
			text: "White/Caucasian",
			value: "White/Caucasian",
		},
		{
			text: "Hispanic/Latin",
			value: "Hispanic/Latin",
		},
		{
			text: "Other",
			value: "Other",
		},
	];

	const user = {
		firstName: "John",
		lastName: "Doe",
		city: "Cincinnati",
		birthdate: "2018-02-04",
		phoneNumber: "123321123",
		country: "USA",
		gender: "Male",
		ethnicity: "Other",
		isOpenToReceiveProducts: true,
	};

	const [firstName, setFirstName] = useState<string>(user.firstName);
	const [lastName, setLastName] = useState<string>(user.lastName);
	const [city, setCity] = useState<string>(user.city);
	const [birthdate, setBirthdate] = useState<string>(user.birthdate);
	const [phoneNumber, setPhoneNumber] = useState<string>(user.phoneNumber);
	const [country, setCountry] = useState<string>(user.country);
	const [gender, setGender] = useState<string>(user.gender);
	const [ethnicity, setEthnicity] = useState<string>(user.ethnicity);
	const [
		isOpenToReceiveProducts,
		setIsOpenToReceiveProducts,
	] = useState<boolean>(user.isOpenToReceiveProducts);

	const {header, description, className} = props;
	return (
		<div className={classnames(className)}>
			<Header>{header}</Header>
			{description &&
				<p className={styles.description}>{description}</p>
			}

			<div>
				<form className={styles.form}>
					<TextInput
						label="First Name"
						id="first-name"
						className={styles.firstName}
						value={firstName}
						onChange={setFirstName}
					/>
					<TextInput
						label="Last Name"
						id="last-name"
						className={styles.lastName}
						value={lastName}
						onChange={setLastName}
					/>
					<div className={styles.avatar}>
						<CameraIcon />
					</div>
					<TextInput
						label="City"
						id="city"
						className={styles.city}
						value={city}
						onChange={setCity}
					/>
					<Select
						id="country"
						label="Country"
						options={countryList}
						className={styles.country}
						value={country}
						onChange={setCountry}
					/>

					<DateInput
						label="Date of Birth"
						id="date-of-birth"
						className={styles.birthDate}
						value={birthdate}
						onChange={setBirthdate}
					/>

					<TextInput
						label="Phone Number"
						id="phone-number"
						className={styles.phoneNumber}
						value={phoneNumber}
						onChange={setPhoneNumber}
					/>

					<Select
						label="Gender"
						id="gender"
						options={genderList}
						className={styles.gender}
						value={gender}
						onChange={setGender}
					/>
					<Select
						label="Ethnicity"
						id="ethnicity"
						options={ethnicityList}
						className={styles.ethnicity}
						value={ethnicity}
						onChange={setEthnicity}
					/>

					<Checkbox
						id="open-to-receive-free-products"
						className={styles.checkbox}
						value={isOpenToReceiveProducts}
						label="I am open to receiving free products as part of research"
						onChange={setIsOpenToReceiveProducts}
					/>
				</form>
				<Button
					className={styles.save}
					text="Save"
				/>
			</div>

			<p className={styles.deleteAccount}>Delete account</p>
		</div>
	);
}
