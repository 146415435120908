import React, {ReactElement} from "react";
import {NavLink} from "react-router-dom";
import styles from "./profile-sidebar.module.scss";
import classnames, {Argument} from "classnames/bind";

const bStyles = classnames.bind(styles);

export interface ProfileSidebar {
	isMobile?: boolean;
	className?: Argument;
}
export default function ProfileSidebar(props: ProfileSidebar) : ReactElement {
	const {isMobile, className} = props;

	return (
		<div className={bStyles("submenu", className, {isMobile})}>
			<h2 className={styles.header}>Profile</h2>
			<NavLink
				to="/profile/personal"
				className={({isActive}) => bStyles("link", {isActive})}
			>
				Personal Profile
			</NavLink>
			<NavLink
				to="/profile/settings"
				className={({isActive}) => bStyles("link", {isActive})}
			>
				Settings
			</NavLink>
			<NavLink
				to="/profile/tos"
				className={({isActive}) => bStyles("link", {isActive})}
			>
				Terms of Service
			</NavLink>
			<NavLink
				to="/"
				className={({isActive}) => bStyles("link", {isActive})}
			>
				Logout
			</NavLink>
		</div>
	);
}
