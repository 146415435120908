import React, {ReactElement} from "react";
import styles from "./navbar.module.scss";
import {ReactComponent as LogoIcon} from "../../icons/logo.svg";
import {ReactComponent as PersonIcon} from "../../icons/person.svg";

import {ReactComponent as HamburgerMenuIcon} from "../../icons/hamburger-menu.svg";

import {NavLink} from "react-router-dom";
import classnames from "classnames";
export interface NavbarProps {
	clicked?: React.MouseEventHandler<HTMLDivElement>;
	showSubmenu?: boolean;
}


export default function Navbar(props: NavbarProps) : ReactElement {
	return <div className={styles.navbar}>

		{props.showSubmenu ?
			<HamburgerMenuIcon
				className={styles.emptyWrapper}
				onClick={props.clicked}
			/>
			:
			<div className={styles.emptyWrapper}></div>
		}
		<LogoIcon />

		<NavLink className={styles.avatar} to="/profile/personal">
			<PersonIcon className={styles.icon} />
		</NavLink>
		<div className={styles.links}>
			<NavLink
				to="/"
				className={({isActive}) => classnames(styles.link, {[styles.activeLink]: isActive})}
			>
				Dashboard
			</NavLink>
			<NavLink
				to="/interests"
				className={({isActive}) => classnames(styles.link, {[styles.activeLink]: isActive})}
			>
				Interests
			</NavLink>
			<NavLink
				to="/brands"
				className={({isActive}) => classnames(styles.link, {[styles.activeLink]: isActive})}
			>
				Brands
			</NavLink>
		</div>
	</div>;
}
