import React, {ReactElement, useState} from "react";
import styles from "./settings.module.scss";
import {Switch} from "../../../components/input";
import {Header} from "../../../components/header";


export default function Settings(): ReactElement {
	const [newOpportunities, setNewOpportunities] = useState(true);
	const [suggestedBrands, setSuggestedBrands] = useState(false);
	const [newVurveyFeatures, setNewVurveyFeatures] = useState(false);

	return (
		<>
			<Header>Settings</Header>
			<h3 className={styles.subheader}>Email notifications</h3>

			<div className={styles.notifications}>
				<Switch
					className={styles.notification}
					label="New opportunities"
					id="new-opportunities"
					value={newOpportunities}
					onChange={setNewOpportunities}
				/>
				<Switch
					className={styles.notification}
					label="Suggested brands"
					id="suggested-brands"
					value={suggestedBrands}
					onChange={setSuggestedBrands}
				/>
				<Switch
					className={styles.notification}
					label="New features in Vurvey"
					id="new-features"
					value={newVurveyFeatures}
					onChange={setNewVurveyFeatures}
				/>
			</div>
		</>
	);
}
