import React, {ReactElement} from "react";
import styles from "../input.module.scss";
import {Input, InputProps} from "../input";

export interface TextProps extends InputProps {
	value: string;
	onChange: (newValue: string) => void;
}

export const Text = ({value, id, onChange, ...props}: TextProps): ReactElement => (
	<Input {...props} id={id}>
		<input
			id={id}
			className={styles.input}
			type="text"
			value={value}
			onChange={e => onChange(e.target.value)}
		/>
	</Input>
);
