import classnames from "classnames";
import React, {ReactElement} from "react";
import {InputProps} from "../input";
import styles from "./switch.module.scss";

export interface SwitchProps extends InputProps {
	value: boolean;
	onChange: (newValue: boolean) => void;
}


export const Switch = ({id, onChange, value, label, className}: SwitchProps) : ReactElement => (
	<label className={classnames(styles.wrapper, className)}>
		{label}
		<div className={styles.switch}>
			<input
				type="checkbox"
				role="switch"
				className={styles.input}
				id={id}
				checked={value}
				onChange={e => onChange(e.target.checked)}
			/>
			<span className={styles.slider}></span>
		</div>
	</label>
);
