import React, {ReactElement} from "react";
import styles from "./opportunity-card.module.scss";
import {Button} from "../button";
import {Opportunity} from "../../routes/dashboard";
import classnames, {Argument} from "classnames";

export interface OpportunityCardProps {
	className?: Argument;
	opportunity: Opportunity
}

export default function OpportunityCard(props: OpportunityCardProps) : ReactElement {
	const {className, opportunity: {
		title,
		description,
		image,
		showBrandLabel,
		brandLabel,
		brandLogo,
		starts,
		incentive,
	}} = props;

	return (
		<div className={classnames(styles.opportunity, className)}>
			<div className={styles.imageWrapper}>
				<img
					className={styles.image}
					src={image}
					alt="opportunity-photo"
				/>
				{showBrandLabel &&
					<div className={styles.brandLabel}>
						<img
							className={styles.brandLogo}
							src={brandLogo}
							alt="brand-logo"
						/>
						<p>From {brandLabel}</p>
					</div>
				}
			</div>
			<div className={styles.content}>
				<h4 className={styles.title}>{title}</h4>
				<p className={styles.description}>
					{description}
				</p>
				<div className={styles.details}>
					<div className={styles.detail}>
						<p className={styles.detailLabel}>Starts:</p>
						<p className={styles.detailValue}>{starts}</p>
					</div>
					<div className={styles.detail}>
						<p className={styles.detailLabel}>Incentive:</p>
						<p className={styles.detailValue}>{incentive}</p>
					</div>
				</div>

				<Button
					className={styles.button}
					text="Apply Now"
					isFullWidth={true}
				/>
			</div>
		</div>
	);
}
