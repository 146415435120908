import React, {ReactElement, useState} from "react";
import classnames from "classnames/bind";
import styles from "./select.module.scss";
import {ReactComponent as ChevronIcon} from "../../../icons/chevron-down.svg";
import {Input, InputProps} from "../input";


const bStyles = classnames.bind(styles);

export interface Option {
	text: string;
	value: string;
}
export interface SelectProps extends InputProps {
	options: Option[];
	value?: string;
	onChange: (newValue: string) => void;
}

export const Select = (props: SelectProps) : ReactElement => {
	const {id, options, value, onChange} = props;
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const currentOption = options.find(o => o.value === value);

	return (
		<Input {...props} id={id}>
			<div
				id={id}
				className={styles.input}
				onClick={() => setIsOpen(!isOpen)}
				tabIndex={0}
				onBlur={() => setIsOpen(false)}
			>
				{currentOption ? currentOption.text : "Select..."}
				<ChevronIcon className={styles.chevron} />
				{isOpen &&
					<div className={styles.dropdown}>
						<ul className={styles.options}>
							{options.map((option, i) =>
								<li
									className={bStyles(
										"option",
										{selected: option.value === value},
									)}
									key={i}
									onClick={() => onChange(option.value)}
								>
									{option.text}
								</li>)}
						</ul>
					</div>
				}
			</div>
		</Input>
	);
};
