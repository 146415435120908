import React, {useState, ReactElement} from "react";
import Category from "../../components/category";
import {Header} from "../../components/header";
import styles from "./interests.module.scss";

export default function Interests(): ReactElement {
	const categories = [
		"adventure",
		"art & design",
		"automotive",
		"baby care",
		"beauty",
		"community",
		"construction",
		"cooking",
		"fashion",
		"food",
		"gaming",
		"health & wellness",
		"home organization",
		"music",
		"outdoors",
		"personal care",
		"shoes",
		"skin care",
		"snacks",
		"sports",
		"sustainability",
		"technology",
		"travel",
	];

	const [selectedCategories, setSelectedCategories] = useState(["travel"]);

	const selectCategory = (category: string): void => {
		if (selectedCategories.includes(category)) {
			const filteredArray = selectedCategories.filter(item => item !== category);
			setSelectedCategories(filteredArray);
		} else {
			setSelectedCategories([
				...selectedCategories,
				category,
			]);
		}
	};

	return (
		<>
			<Header className={styles.header}>Choose Interests</Header>
			<p className={styles.description}>Select the categories that interest you.</p>
			<div className={styles.categories}>
				{categories.map((category, i) => <Category
					category={category}
					clicked={selectCategory}
					key={i}
					isSelected={selectedCategories.includes(category)}
				/>)}
			</div>
		</>
	);
}
