import React, {ReactElement} from "react";
import {NavLink} from "react-router-dom";
import classnames, {Argument} from "classnames/bind";
import styles from "./dots.module.scss";

const bStyles = classnames.bind(styles);

export interface DotsProps {
	className?: Argument;
}

export const Dots = (props: DotsProps): ReactElement => (
	<div className={bStyles("dots", props.className)}>
		<NavLink
			to="/onboarding/interests"
			className={({isActive}) => bStyles("dot", {active: isActive})}
		>
		</NavLink>
		<NavLink
			to="/onboarding/brands"
			className={({isActive}) => bStyles("dot", {active: isActive})}
		>
		</NavLink>
		<NavLink
			to="/onboarding/profile"
			className={({isActive}) => bStyles("dot", {active: isActive})}
		>
		</NavLink>
	</div>
);


