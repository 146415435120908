import React, {ReactElement} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "../../../components/button";
import {Dots} from "../../../components/dots";
import {FlexibleNavbar} from "../../../components/flexible-navbar";
import Layout from "../../../components/layout";
import Brands from "../../brands";

const OnboardingBrands = (): ReactElement => {
	const navigate = useNavigate();

	return (
		<>
			<FlexibleNavbar>
				<Dots />
				<Button text="Next" clicked={() => navigate("/onboarding/profile")}/>
			</FlexibleNavbar>
			<Layout>
				<Brands />
			</Layout>
		</>
	);
};

export {OnboardingBrands};
