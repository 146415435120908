import React, {ReactElement} from "react";
import styles from "./brand-card.module.scss";
import classnames from "classnames/bind";
import {ReactComponent as CheckIcon} from "../../icons/check.svg";
import {Brand} from "../../routes/brands";

const bStyles = classnames.bind(styles);

export interface BrandCardProps {
	brand: Brand;
	selected: boolean;
	clicked: React.MouseEventHandler<HTMLDivElement>;
}
export default function Category(props: BrandCardProps) : ReactElement {
	const {brand, selected, clicked} = props;

	return <div className={bStyles("brandCard", {selected})}
		onClick={clicked}
	>
		{brand.logoUrl ?
			<img className={styles.logo} src={brand.logoUrl} /> :
			<div className={bStyles("logo", "emptyLogo")}></div>
		}
		<div>
			<p className={styles.name}>{brand.name}</p>
			<p className={styles.description}>{brand.description}</p>
		</div>

		{selected && <div className={styles.icon}>
			<CheckIcon />
		</div>
		}
	</div>;
}
