import React, {ReactElement} from "react";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import App from "./App";
import {render} from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {createUploadLink} from "apollo-upload-client";
import {config} from "./config";

const rootElement = document.getElementById("root");
import {setContext} from "@apollo/client/link/context";

const Setup = (): ReactElement => {
	const uploadLink = createUploadLink({
		uri: `${config.apiHost}/graphql`,
		credentials: "include",
	});

	const csrfTokenLink: any = setContext((_, {headers}) => {
		return {
			headers: {
				...headers,
				"x-csrf-token": localStorage.getItem("csrfToken") || "",
			},
		};
	});
	const client = new ApolloClient({
		link: csrfTokenLink.concat(uploadLink),
		cache: new InMemoryCache(),
	});

	if (client === undefined) {
		return (
			<div>Loading...</div>
		);
	}

	return (
		<ApolloProvider client={client}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</ApolloProvider>
	);
};

render(
	<Setup />,
	rootElement,
);
