import React, {ReactElement} from "react";
import classnames from "classnames";
import styles from "./checkbox.module.scss";
import {InputProps} from "../input";


export interface CheckboxProps extends InputProps {
	value: boolean;
	onChange: (newValue: boolean) => void;
}

const Checkbox = ({id, onChange, value, label, className}: CheckboxProps): ReactElement => (
	<div className={classnames(styles.container, className)}>
		<input
			checked={value}
			onChange={e => onChange(e.target.checked)}
			id={id}
			className={styles.checkbox}
			type="checkbox"
		/>
		<label htmlFor={id} className={styles.label}>
			{label}
		</label>
	</div>
);

export {Checkbox};
