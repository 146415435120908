import React, {ReactElement, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Layout from "../../../components/layout";
import styles from "./search.module.scss";
import {ReactComponent as CampfireIcon} from "../../../icons/campfire.svg";
import {ReactComponent as CarSideIcon} from "../../../icons/car-side.svg";
import {ReactComponent as FaceSmileRelaxedIcon} from "../../../icons/face-smile-relaxed.svg";
import {ReactComponent as HouseIcon} from "../../../icons/house.svg";
import {ReactComponent as PizzaSliceIcon} from "../../../icons/pizza-slice.svg";

const OnboardingSearch = (): ReactElement => {
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			navigate("/");
		}, 4200);
	}, []);

	return (
		<Layout className={styles.layout}>
			<div className={styles.icons}>
				<div className={styles.circle}>
					<HouseIcon />
				</div>
				<div className={styles.circle}>
					<FaceSmileRelaxedIcon />
				</div>
				<div className={styles.circle}>
					<CampfireIcon />
				</div>
				<div className={styles.circle}>
					<CarSideIcon />
				</div>
				<div className={styles.circle}>
					<PizzaSliceIcon />
				</div>
			</div>

			<h3 className={styles.header}>Searching for matches...</h3>

			<div className={styles.bar}>
				<div className={styles.in}></div>
			</div>
		</Layout>
	);
};

export {OnboardingSearch};
