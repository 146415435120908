import React, {ReactElement, useState} from "react";
import classnames from "classnames/bind";

import styles from "./profile.module.scss";
import Navbar from "../../components/navbar";
import Layout from "../../components/layout";
import ProfileSidebar from "../../components/profile-sidebar";
import {Outlet} from "react-router-dom";


const bStyles = classnames.bind(styles);

export default function Profile(): ReactElement {
	const [mobileSubmenuOpen, setMobileSubmenuOpen] = useState<boolean>(false);

	const handleOpenSubmenu = (e: any): void => {
		e.stopPropagation();
		setMobileSubmenuOpen(true);
	};


	return (
		<>
			<div
				onClick={() => setMobileSubmenuOpen(false)}
				className={bStyles("profileLayout", {mobileSubmenuOpen})}
			>
				<Navbar clicked={handleOpenSubmenu} showSubmenu={true} />
				<div className={styles.container}>
					<ProfileSidebar />
					<Layout>
						<Outlet />
					</Layout>
				</div>
			</div>

			{mobileSubmenuOpen && <ProfileSidebar isMobile={true} />}
		</>
	);
}
