import React, {useState, ReactElement} from "react";
import styles from "./brands.module.scss";
import BrandCard from "../../components/brand-card";
import {Header} from "../../components/header";

export interface Brand {
	id: number;
	name: string;
	description: string;
	logoUrl: string;
}

export default function Brands(): ReactElement {
	const brands : Brand[] = [
		{
			id: 1,
			name: "Adidas",
			description: "Fashion and footwear",
			logoUrl: "https://qph.fs.quoracdn.net/main-qimg-a4552164769003229fd9540237008069.webp",
		},
		{
			id: 2,
			name: "AAA",
			description: "Automotive and travel",
			logoUrl: "",
		},
		{
			id: 3,
			name: "Air Wick",
			description: "Home fragence",
			logoUrl: "",
		},
		{
			id: 4,
			name: "Brand name",
			description: "Brand description",
			logoUrl: "",
		},
		{
			id: 5,
			name: "Brand name",
			description: "Brand description",
			logoUrl: "",
		},
		{
			id: 6,
			name: "Brand name",
			description: "Brand description",
			logoUrl: "",
		},
		{
			id: 7,
			name: "Brand name",
			description: "Brand description",
			logoUrl: "",
		},
		{
			id: 8,
			name: "Brand name",
			description: "Brand description",
			logoUrl: "",
		},
		{
			id: 9,
			name: "Brand name",
			description: "Brand description",
			logoUrl: "",
		},
		{
			id: 10,
			name: "Brand name",
			description: "Brand description",
			logoUrl: "",
		},
	];

	const [selectedBrands, setSelectedBrands] = useState<Brand[]>([]);

	const selectBrand = (brand: Brand): void => {
		if (selectedBrands.map(item => item.id).includes(brand.id)) {
			const filteredArray = selectedBrands.filter(item => item.id !== brand.id);
			setSelectedBrands(filteredArray);
		} else {
			setSelectedBrands(currentBrands => {
				return [...currentBrands, brand];
			});
		}
	};

	return (
		<>
			<Header className={styles.header}>Follow Brands</Header>
			<p className={styles.description}>
				Follow the brands that you want to collaborate with.
			</p>
			<div className={styles.brands}>
				{brands.map((brand, i) => <BrandCard
					brand={brand}
					selected={selectedBrands.map(item => item.id).includes(brand.id)}
					clicked={() => selectBrand(brand)}
					key={i}
				/>)}
			</div>
		</>
	);
}
