import React, {ReactElement} from "react";
import {Outlet} from "react-router-dom";
import styles from "./container.module.scss";

const OnboardingContainer = (): ReactElement => (
	<div className={styles.container}>
		<Outlet />
	</div>
);

export {OnboardingContainer};
