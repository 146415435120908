import React, {ReactElement} from "react";
import {Outlet} from "react-router-dom";

import Layout from "../../components/layout";
import Navbar from "../../components/navbar";
import styles from "./layout-with-navbar.module.scss";


export default function LayoutWithNavbar(): ReactElement {
	return (
		<div className={styles.container}>
			<Navbar />
			<Layout>
				<Outlet />
			</Layout>
		</div>
	);
}
