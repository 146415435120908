import React, {ReactElement} from "react";
import styles from "./button.module.scss";
import classnames, {Argument} from "classnames";
export interface ButtonProps {
	clicked?: React.MouseEventHandler<HTMLButtonElement>;
	text: string;
	isFullWidth?: boolean;
	className?: Argument;
	style?: "navy" | "bolt";
}


const Button = (props: ButtonProps): ReactElement => {
	const btnStyles = classnames(
		styles.button,
		props.className,
		styles[props.style ?? "bolt"],
		props.isFullWidth && styles.fullWidth,
	);

	return <button
		className={btnStyles}
		onClick={props.clicked}
	>
		{props.text}
	</button>;
};

export {Button};
