import React, {ReactElement} from "react";
import {Header} from "../../components/header";
import OpportunityCard from "../../components/opportunity-card";
import styles from "./dashboard.module.scss";
import {ReactComponent as FlagIcon} from "../../icons/flag.svg";
import {ReactComponent as ClockRotateRightIcon} from "../../icons/clock-rotate-right.svg";
import {ReactComponent as PersonIcon} from "../../icons/person.svg";
import {Tips, TipInterface} from "../../components/tips";

export interface Opportunity {
	title: string;
	description: string;
	image?: string;
	showBrandLabel?: boolean;
	brandLabel?: string;
	brandLogo?: string;
	starts: string;
	incentive?: string;
}

export default function Dashboard(): ReactElement {
	const tips: TipInterface[] = [
		{
			title: "Follow the brands you love",
			description: `We want you to be excited about the feedback you're giving,
				so tell us which brands you love and
				we'll match you with opportunities.`,
			icon: <FlagIcon />,
		},
		{
			title: "Respond quickly",
			description: `Quickly respond to the opportunities we send
				your way so brands know you're interested.`,
			icon: <ClockRotateRightIcon />,
		},
		{
			title: "Keep your info up to date",
			description: `Keep your profile and interests up to date.
				Got a new hobby that you're really into? We want to know about it!`,
			icon: <PersonIcon />,
		},
	];
	const opportunities: Opportunity[] = [
		{
			title: "Women who love snow sports",
			description: `We're looking for women in Seattle,
				San Francisco, or Los Angeles who ski or snowboard`,
			image: `https://img.mybaze.com/P4N_ZmlQAJxMV7zZ0H0lS36mJvj8a0rF8EmANHx3iP8/w:800/
				aHR0cHM6Ly9tZWRpYS5teWJhemUuY29tL21lZGlh
				L29yaWdpbmFsX2ltYWdlcy9rb2JpZXR5X25hX25hcnRhY2guanBlZw.jpg`,
			showBrandLabel: true,
			brandLabel: "Timberland",
			brandLogo: `https://e7.pngegg.com/pngimages/980/186/
				png-clipart-the-timberland-company-logo-shoe-footwear-boot-boot-leaf-company.png`,
			starts: "In about a week",
			incentive: "25$ if selected",
		},
		{
			title: "Women who love snow sports",
			description: "todo",
			showBrandLabel: false,
			starts: "In about a week",
			incentive: "25$ if selected",
		},
	];

	let descriptionText;
	const opportunityCount = opportunities.length;

	if (opportunityCount === 0) {
		descriptionText = "You have no new opportunities at this time.";
	} else {
		descriptionText = `We found ${opportunityCount}
			${opportunityCount === 1 ? "match" : "matches"} for you`;
	}

	return (
		<div className={styles.container}>
			<div className={styles.opportunitiesContainer}>
				<Header className={styles.header}>Dashboard</Header>
				<p className={styles.description}>{descriptionText}</p>

				<div className={styles.opportunities}>
					{opportunities.map((opportunity, i) => <OpportunityCard
						opportunity={opportunity}
						key={i}
					/>)}
				</div>
			</div>

			<Tips
				className={styles.tips}
				header="Tips for Success"
				tips={tips}
			/>

		</div>
	);
}
