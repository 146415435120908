import React, {ReactElement} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "../../../components/button";
import {Dots} from "../../../components/dots";
import {FlexibleNavbar} from "../../../components/flexible-navbar";
import Layout from "../../../components/layout";
import PersonalProfile from "../../profile/personal";
import styles from "./profile.module.scss";

const OnboardingProfile = (): ReactElement => {
	const navigate = useNavigate();
	return (
		<>
			<FlexibleNavbar>
				<Dots />
				<Button
					style="navy"
					text="Finish"
					clicked={() => navigate("/onboarding/search")}
				/>
			</FlexibleNavbar>
			<Layout>
				<div className={styles.container}>
					<PersonalProfile
						className={styles.personalProfile}
						header="Complete your Profile"
						description="Complete your profile to get matched with opportunities."
					/>
				</div>
			</Layout>
		</>
	);
};

export {OnboardingProfile};
